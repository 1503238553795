html, body{
  font-family: 'Gotham-light';
  font-size:16px;
}
.App {
  text-align: center;
}

.q-title{font-family: 'Gotham-Medium';letter-spacing:-0.4px;}
.initialpage .text-step img{filter: brightness(3) !important;}
.list-group li.manLi{margin-bottom:5px}
.modal-dialog.modal-100w {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  max-width: 100%;
  max-height: 100%;
}
.hidemenuBtn{background-color: rgb(120, 181, 188);
  z-index: 1;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 7px 25px;
  border: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;}
  .showmenu{background-color: rgb(120, 181, 188);
    z-index: 1;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 7px 25px;
    border: 0;position: absolute;
    right: 0;border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    z-index: 1;}
  .showmenu img, .hidemenuBtn img{height: 25px;margin-left: 40px;
    width: 15px;}
.theme-text-btn img{width:12px;height:25px;margin-left:5px;}
.theme-text-btn{color: #78b5bc !important;
  font-weight: bold !important;
  height: 45px;
  background-color: #fff !important;
  border: none !important;}
.orng-text img{width:12px;height:25px;margin-left:5px;}
.orng-text{color: #ff5100 !important;
  font-weight: bold !important;
  height: 45px;
  background-color: #fff !important;
  border: none !important;}
.modal-dialog.modal-100w .modal-content{height: 100%;border: 0;border-radius: 0;background-color: transparent;}
.modal-dialog.modal-100w .modal-header{border: 0;}
.modal-dialog.modal-100w .model-body{padding-top: 0;}
.wiz-nav-menu-ul li.active{color: #ff5100 ;font-weight:bold;font-family: 'Gotham-Medium';}
.react-confirm-alert-overlay{background-color: rgba(68,97,96,.9) !important;}
.react-confirm-alert{
  font-family: Gotham;
  background: #fff;
  border: 1px solid #fff;
  padding: 10px;
  max-width: 40%;
  top: 11%;
  position: absolute;
}
.react-confirm-alert h1{color: rgb(255, 81, 0);}
.text-step{
  text-align: right;
  color: white;
  top: 2%;
  position: absolute;
  float: right;
  right: 2%;
  font-size: 1rem;
}
.playMain>div{
  justify-content: center;
  display: flex;
}
.child-label .form-check-label{background-color: #81C2CA;
  padding: 4px 50px 4px 40px;
  border-radius: 10px;
  margin-left: -20px;}
.child-label.form-check input[type=radio]{
  left: 5px;}
.child-label .checkmark {left: 5px;}

input.form-control , select.form-control{max-width: 300px;padding: 0 0 0 8px !important;}

.Loader {
  height: 100vh;
  width: 100%;
  align-items: center;
  display: table;
}
.Loader>div{    
  align-items: center;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.mt-5, .my-5 {
  margin-top: 5rem!important;
}
/* button design */
.com-btn{
  text-align: center;
  flex-direction: row;
  border-radius: 100px !important;
  margin: auto;
  font-size:1rem;
  font-family: 'Gotham-Medium';
  font-weight: 600 !important;
  letter-spacing: 0.1px;
  border: 0px !important;
  display: inline-flex;
    align-items: center;
    padding: 0 18px 0 25px;
    line-height: 49px;
    justify-content: center;
    height: 50px;
}
.orng-btn{
  color: rgb(255, 255, 255) !important;
  background-color: rgb(255, 81, 0) !important;
  border-color: rgb(255, 255, 255) !important;
}
.gray-btn{  
  background-color: rgb(241, 241, 241) !important;
  border-color: rgb(241, 241, 241) !important;
  color: rgb(0, 0, 0) !important;
}
.com-btn img{
  height: 25px;vertical-align: bottom;
  width: 12px;
  margin-left: 7px;
  margin-right: 7px;
 }
/* button design */
/* radio custom design */
/* Customize the label (the container) */
.form-check {
  display: block;
  position: relative;
  padding-left: 16px !important;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-check label{margin-left: 1rem;color:#212529 !important ;}

/* Hide the browser's default radio button */
.form-check input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 25px;
  width: 25px;
  z-index: 1;
  top: 0;
  left: 0;
  margin:auto;bottom:0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 20%;
    margin:auto;bottom:0;
}

/* On mouse-over, add a grey background color */
.form-check:hover input ~ .checkmark {
  background-color: rgb(255, 81, 0);
}

/* When the radio button is checked, add a blue background */
.form-check input:checked ~ .checkmark {
  background-color: rgb(255, 81, 0);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.form-check input:checked ~ .checkmark:after {
  display: block;
}

.mg-top-1-re{margin-top: 2rem;}
.rnd-cr-2-re{border-radius: 2rem !important;}
.img-64{height: 64px;width: 64px!important;}
.alg-center{text-align: center;}
.n-bg{background: none;background-image: none;background-color: transparent !important;}
.n-bdr{border: none;}

/* wizard css */
.wiz-nav{height: 100vh;overflow: hidden;}
.wiz-nav .wiz-nav-top{height: 10%;}
.wiz-nav .wiz-nav-middle{padding-left: 15px;height: 80%;overflow: auto;margin: auto;align-items: center;
  align-content: center;
  display: flex;}
.wiz-nav .wiz-nav-bottom{height: 10%;}
.wiz-nav-menu-ul li.active:before{background-color: #ff5100;
  position: absolute;
  left: -15px;
  content: "";
  width: 25px;
  height: 20px;
  margin: auto;
  top: 0;
  bottom: 0;}
.wiz-nav-menu-ul li{position:relative}

/* wizard css */
/* perio custom css */
.fw-bold{font-weight: bold;}
/* responsive */
sup { top: 0em; }
img{max-width:100%}
.manLi.list-group-item{padding-left:15px;}
.manLi.list-group-item span{position:absolute;left:0}
.homepage .card-deck .card{padding-bottom:80px;}
.homepage .card-footer{position:absolute;bottom:0;}
.mainmenubtn, #menuopen, #menuclose{display:none}
@media (max-width:992px){
  .wiz-nav-menu-ul.settings-menu { display: block ; }
  .wiz-nav-menu-ul li.active {
    color: #ff5100 !important;
}
.wiz-nav-menu-ul li {
  color: #495057 !important;
}
.initialpage  .wiz-nav-bottom{display:none !important}
  .wiz-nav-bottom{position: absolute;
    height: 40px;
    top: 55px;
    right: 0px;}
  .wiz-nav-menu-ul{display: none;}
  .wiz-nav-middle.toggle{box-shadow:17px 0px 20px 0px rgb(0 0 0 / 28%)}
  .wiz-nav-middle.toggle .wiz-nav-menu-ul{display: block;align-self: flex-start;}
  .wiz-nav-middle #menuopen{display:block}
  .wiz-nav-middle .menulogo{display:none}
  #menuclose, .mainmenubtn{color:#212529 !important}
  .wiz-nav-middle.toggle .menulogo{display:block}
  .wiz-nav-middle.toggle #menuopen{display:none}
  .wiz-nav-middle #menuclose{display:none}
  .mainmenubtn{display:none}
  .wiz-nav-middle.toggle .mainmenubtn{display:block}
  .wiz-nav-middle.toggle #menuclose{display:block}
  .wiz-nav-middle{transition: left 2s;
    left: -280px;}
  .wiz-nav-middle.toggle{position: fixed;
    left: 0;
    top: 0;
    background: #fff;padding-left: 0;
    z-index: 10;
    height: 100vh;flex-direction: column;
    width: 90%;}
  .dso_name.ml-3{margin-left: 0 !important;}
  .modal{background-position:center right;background-size:cover !important}
  input.form-control , select.form-control{max-width: 100%;}
  .initialpage{background-image:none !important}
  .child-label .form-check-label{padding:6px 10px 6px 40px}
  .text-step{text-align: left;
    padding-left: 30px;width:100%}
  .font-s{font-size:1rem;line-height:1.2rem}
  .font-l{font-size:2rem;line-height:2.2rem;font-weight:bold;}
  .font-m{font-size:1.2rem;line-height:1.6rem}
  .font-xl{font-size:3rem;line-height:3.2rem;font-weight:bold;}
  
  .footrlogo{position:relative !important}
  .maincontent {max-height:none !important}
  .com-btn{padding:0 10px 0 12px ;font-size: .8rem;height:45px;line-height:45px;}
  
  .wiz-nav{height:auto;}

  .beginContent{text-align: right;}
}
@media (min-width:993px){
  html, body, #root, #root>div{height: auto;}
  
  .font-s{font-size:1.1rem;line-height:1.3rem}
  .font-l{font-size:2.6rem;line-height:2.9rem;font-weight:bold;}
  .font-m{font-size:1.5rem;line-height:1.8rem}
  .font-xl{font-size:4rem;line-height:4.3rem;font-weight:bold;}
}
@media (min-width:1201px){
  .font-s{font-size:1.2rem;line-height:1.6rem}
  .font-l{font-size:3rem;line-height:3.4rem;font-weight:bold;}
  .font-m{font-size:1.6rem;line-height:2rem}
  .font-xl{font-size:6rem;line-height:6.4rem;font-weight:bold;}
}
@media (min-width: 1500px){
.col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}}

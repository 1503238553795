body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Gotham';
  src: local('GothamBook'), url(./assets/fonts/GothamBook.ttf) format('truetype');
}
@font-face {
  font-family: 'Gotham-Light';
  
  src: url('./assets/fonts/gotham-light-webfont.eot');
    src: url('./assets/fonts/gotham-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/gotham-light-webfont.woff2') format('woff2'),
         url('./assets/fonts/gotham-light-webfont.woff') format('woff'),
         url('./assets/fonts/gotham-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'Gotham-Medium';
  src: url('./assets/fonts/Gotham-Medium.eot');
  src: url('./assets/fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Gotham-Medium.woff2') format('woff2'),
       url('./assets/fonts/Gotham-Medium.woff') format('woff'),
       url('./assets/fonts/Gotham-Medium.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;

}
@font-face {
  font-family: 'Gotham-Book';
  src: local('GothamBook'), url(./assets/fonts/GothamBook.ttf) format('truetype');
}

